<template>
	<v-dialog :max-width="$vuetify.breakpoint.mdAndUp ? '50vw' : null" persistent scrollable :value="open" @input="closeDialog">
		<v-card>
			<v-card-title :class="$vuetify.breakpoint.mdAndUp ? 'secondary' : 'pl-2 pr-0 py-0 secondary'">
				<v-layout row wrap align-center justify-space-around>
					<v-flex v-t="action == 'create' ? 'sa.geds.create_catalog_tree_structure' : 'sa.geds.update_catalog_tree_structure'" grow title white--text></v-flex>
					<v-flex shrink>
						<v-btn class="white--text" icon @click="closeDialog()">
							<v-icon>close</v-icon>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-card-title>
			<v-card-text>
				<v-layout v-if="open" row wrap>
					<v-flex xs12>
						<v-text-field v-model="catalogTreeStructure.name" autofocus :label="$t('name')" maxlength="191" @keypress.enter="save"></v-text-field
					></v-flex>
				</v-layout>
			</v-card-text>
			<v-card-actions>
				<v-spacer /><v-btn color="primary" :disabled="!catalogTreeStructure.name" flat @click="save">{{ $t('actions.validate') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import SuperAdminCatalogTreeStructureModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminCatalogTreeStructureModuleGuard'

export default {
	name: 'DialogCatalogTreeStructure',
	mixins: [SuperAdminCatalogTreeStructureModuleGuard],
	props: {
		action: {
			default: () => 'create',
			type: String
		},
		open: {
			required: true,
			type: Boolean
		},
		value: {
			default: () => ({}),
			required: false,
			type: Object
		}
	},
	data: function () {
		return {
			catalogTreeStructure: {}
		}
	},
	watch: {
		open: {
			handler: function (val) {
				if (val) {
					if (this.action === 'create') {
						this.catalogTreeStructure = {}
					} else {
						this.catalogTreeStructure = { ...this.value }
					}
				}
			},
			immediate: true
		}
	},
	methods: {
		closeDialog: function () {
			this.$emit('close')
		},
		createCatalogTreeStructure: function () {
			this.service.createCatalogTreeStructure(this.catalogTreeStructure).then(res => {
				this.$emit('add', res)
				this.$emit('close')
			})
		},
		save: function () {
			let result = Promise.resolve()
			if (this.action === 'create') {
				this.createCatalogTreeStructure()
			} else {
				this.updateCatalogTreeStructure()
			}
			return result
		},
		updateCatalogTreeStructure: function () {
			this.service.updateCatalogTreeStructure(this.value.id, this.catalogTreeStructure).then(res => {
				this.$emit('input', res)
				this.$emit('close')
			})
		}
	}
}
</script>
